import React, { createContext, useContext, useState } from 'react'

interface KlaviyoContext {
    klaviyo: any
    setKlaviyo: (klaviyo: any) => void
}

const defaultContextValue: KlaviyoContext = {
    klaviyo: null,
    setKlaviyo: () => {},
}

const KlaviyoContext = createContext<KlaviyoContext>(defaultContextValue)

export const useKlaviyo = () => useContext(KlaviyoContext)

interface KlaviyoProviderProps {
    children?: React.ReactNode
}

export const KlaviyoProvider = ({ children }: KlaviyoProviderProps) => {
    const [klaviyo, setKlaviyo] = useState<any>(null);

    return (
        <KlaviyoContext.Provider value={{klaviyo, setKlaviyo}}>
            {children}
        </KlaviyoContext.Provider>
    )
}