export const medusaUrl = typeof process !== 'undefined' ?? (process.env.MEDUSA_ADMIN_BACKEND_URL || process.env.VITE_MEDUSA_ADMIN_URL || 'http://localhost:9000');
export const CUT_OFF_HOUR = 9;
export const CUT_OFF_DAYS = 1;
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        medusaUrl,
        CUT_OFF_HOUR,
        CUT_OFF_DAYS
    };
}
