'use client'

import { medusaClient } from '@lib/config'
import { Customer, ProductVariant } from '@medusajs/medusa'
import { useMutation } from '@tanstack/react-query'
import { useMeCustomer } from 'medusa-react'
import { useRouter } from 'next/navigation'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Subscription } from '../../types/medusa'
import { useStore } from '@lib/context/store-context'
import { useSubscription } from '@lib/context/subscription-context'

export enum LOGIN_VIEW {
    SIGN_IN = 'sign-in',
    REGISTER = 'register',
}

interface AccountContext {
    customer?: Omit<Customer, 'password_hash'>
    isLoadingCustomer: boolean
    loginView: [LOGIN_VIEW, React.Dispatch<React.SetStateAction<LOGIN_VIEW>>]
    checkSession: () => void
    refetchCustomer: () => void
    handleLogout: () => void
    getSubscriptions: () => Promise<Subscription[]>
    updateSubscription: (subscriptionId: string, data: any) => Promise<Subscription>
    getSubscription: (subscriptionId: string) => Promise<Subscription>
    getProductVariants: () => Promise<ProductVariant[]>
    hasLoggedOut: boolean
    isCreatingSubscription: boolean
    setIsCreatingSubscription: (val: boolean) => void
    pollForSubscription: () => void
    isLoggingOut?: boolean
}

const AccountContext = createContext<AccountContext | null>(null)

interface AccountProviderProps {
    children?: React.ReactNode
}

const handleDeleteSession = () => {
    return medusaClient.auth.deleteSession()
}

const IS_SERVER = typeof window === 'undefined'
const CART_KEY = 'medusa_cart_id'
export const AccountProvider = ({ children }: AccountProviderProps) => {
    const {
        customer,
        isLoading: isLoadingCustomer,
        refetch,
        remove,
    } = useMeCustomer({
        onError: () => {},
    })
    const [isCreatingSubscription, setIsCreatingSubscription] = useState(false)
    const [isLoggingOut, setIsLoggingOut] = useState(false)
    const loginView = useState<LOGIN_VIEW>(LOGIN_VIEW.SIGN_IN)
    const router = useRouter()
    const { setSubscription } = useSubscription()
    const [hasLoggedOut, setHasLoggedOut] = useState(false)
    const checkSession = useCallback(() => {
        if (!customer && !isLoadingCustomer) {
            router.push('/account/login')
        }
    }, [customer, isLoadingCustomer, router])

    const getSubscriptions = async (): Promise<Subscription[]> => {
        return (await medusaClient.client
            .request('GET', `/store/customers/me/subscriptions?expand=cart,cart.items.variant.product,cart.items.variant.options`)
            .then(data => {
                return data.subscriptions
            })) as Subscription[]
    }

    useEffect(() => {
        const loadSubscription = async () => {
            if (isLoadingCustomer) return

            if (!customer) {
                setSubscription(null)
                return
            }

            const subscriptions = await getSubscriptions()
            if (subscriptions?.length) {
                const activeSubscription = subscriptions.find(subscription => subscription.status !== 'canceled')
                if (activeSubscription) {
                    setSubscription(activeSubscription)
                } else {
                    setSubscription(null)
                }
            } else {
                setSubscription(null)
            }
        }

        loadSubscription()
    }, [customer, isLoadingCustomer])

    const useDeleteSession = useMutation({
        mutationFn: handleDeleteSession,
        mutationKey: ['delete-session'],
    })

    const handleLogout = () => {
        setIsLoggingOut(true)
        setSubscription(null)
        if (!IS_SERVER) {
            localStorage.removeItem(CART_KEY)
        }
    

        setHasLoggedOut(true)
        useDeleteSession.mutate(undefined, {
            onSuccess: () => {
                remove()
                setHasLoggedOut(false)
                setIsLoggingOut(false)
                loginView[1](LOGIN_VIEW.SIGN_IN)
                router.push('/')
            },
        })
    }

    const getSubscription = async (subscriptionId: string): Promise<Subscription> => {
        return (await medusaClient.client
            .request('GET', `/store/customers/me/subscriptions/${subscriptionId}`)
            .then(data => {
                return data.subscription
            })) as Subscription
    }

    const pollForSubscription = async (count: number = 0) => {
        const subscriptions = await getSubscriptions()
        if (subscriptions && subscriptions.length > 0) {
            setIsCreatingSubscription(false)
            const activeSubscription = subscriptions.find(subscription => subscription.status !== 'canceled')
            if (activeSubscription) {
                setSubscription(activeSubscription)
            }
        } else {
            if (count < 10) {
                setTimeout(() => {
                    pollForSubscription(count + 1)
                }, 1000)
            }
        }
    }

    const updateSubscription = async (subscriptionId: string, data: any): Promise<Subscription> => {
        return (await medusaClient.client
            .request('POST', `/store/customers/me/subscriptions/new/${subscriptionId}`, data)
            .then(data => {
                setSubscription(data)
                return data
            })
            .catch(err => console.log(err))) as Subscription
    }

    const getProductVariants = async () => {
        return (await medusaClient.client.request('GET', `/store/variants`).then(data => {
            return data.variants
        })) as ProductVariant[]
    }

    return (
        <AccountContext.Provider
            value={{
                customer,
                isLoadingCustomer,
                loginView,
                checkSession,
                refetchCustomer: refetch,
                handleLogout,
                getSubscriptions,
                getSubscription,
                updateSubscription,
                getProductVariants,
                hasLoggedOut,
                isCreatingSubscription,
                setIsCreatingSubscription,
                pollForSubscription,
                isLoggingOut,
            }}
        >
            {children}
        </AccountContext.Provider>
    )
}

export const useAccount = () => {
    const context = useContext(AccountContext)

    if (context === null) {
        throw new Error('useAccount must be used within a AccountProvider')
    }
    return context
}
