'use client'

import { medusaClient } from '@lib/config'
import { handleError } from '@lib/util/handle-error'
import { Region } from '@medusajs/medusa'
import { useCart, useCreateLineItem, useDeleteLineItem, useUpdateLineItem } from 'medusa-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useAccount } from '@lib/context/account-context'
import { useSubscription } from '@lib/context/subscription-context'
import { useKlaviyo } from './klaviyo'

interface StoreContext {
    checkIfPostalCodeCheckIsNeeded: () => boolean
    resetSearch: boolean
    setResetSearch: (val: boolean) => void
    searchIsOpen: boolean
    handleSearch: () => void
    validatePostalCode: (postalCode: string) => Promise<any>
    createPostalCodeSignup: (payload: PostalCodeSignupPayload) => Promise<any>
    showPostalCodeCheck: boolean
    nextDeliveryDay: Date | undefined
    setShowPostalCodeCheck: (value: boolean) => void
    deliveryDayForChosenPostalCode: string | undefined
    setDeliveryDayForChosenPostalCode: (value: string) => void
    setChosenPostalCode: (value: string | null) => void
    chosenPostalCode: string | null
    postalCodeCheckResult: string | null
    setPostalCodeCheckResult: (value: string | null) => void
    countryCode: string | undefined
    setRegion: (regionId: string, countryCode: string) => void
    addItem: (variantId: string, quantity: number, payload?: Record<string, string>) => void
    addProductBeforePostalCodeCheck: (id: string | null) => void
    getProductBeforePostalCodeCheck: () => string | null
    updateItem: (
        lineId: string,
        quantity: number,
        payload?: Record<string, string | number | null>,
        callback?: () => void,
    ) => void
    deleteItem: (lineId: string) => void
    resetCart: () => void
    deleteCartId: () => void
    quantityUpdating?: { [key: string]: boolean }
    setEmail: (email: string) => void
    showPostcodeCheckButton: boolean
    setPostcodeCheckButton: (value: boolean) => void
    openPostcodeCheck: () => void
    frequencyOptions: { value: number; label: string }[]
}

interface PostalCodeSignupPayload {
    code: string
    email: string
    type: string
}

const StoreContext = React.createContext<StoreContext | null>(null)

export const useStore = () => {
    const context = React.useContext(StoreContext)
    if (context === null) {
        throw new Error('useStore must be used within a StoreProvider')
    }
    return context
}

interface StoreProps {
    children: React.ReactNode
    hasPostcode: boolean
}

const IS_SERVER = typeof window === 'undefined'
const CART_KEY = 'medusa_cart_id'
const REGION_KEY = 'medusa_region'
export const StoreProvider = ({ children, hasPostcode }: StoreProps) => {
    const { createCart, cart, setCart, updateCart } = useCart()
    const { subscription } = useSubscription()
    const [countryCode, setCountryCode] = useState<string | undefined>(undefined)
    const addLineItem = useCreateLineItem(cart?.id!)
    const isCreatingNewCart = useRef(false)
    const removeLineItem = useDeleteLineItem(cart?.id!)
    const adjustLineItem = useUpdateLineItem(cart?.id!)
    const [quantityUpdating, setQuantityUpdating] = useState({})
    const today = new Date()
    const [postalCodeCheckResult, setPostalCodeCheckResult] = useState<string | null>(null)
    const [deliveryDayForChosenPostalCode, setDeliveryDayForChosenPostalCode] = useState<string | undefined>(undefined)
    //const [cutOffDayForChosenPostalCode, setCutOffDayForChosenPostalCode] = useState<string | undefined>(undefined)
    const nextDeliveryDay = useMemo(() => {
        return subscription ? new Date(subscription.renewal_at) : cart?.next_delivery_at ? new Date(cart.next_delivery_at) : undefined
    }, [subscription, cart])
    const [chosenPostalCode, setChosenPostalCode] = useState<string | null>(null)
    const [searchIsOpen, setsearchIsOpen] = useState(false)
    const [showPostalCodeCheck, setShowPostalCodeCheck] = useState(false)
    const [resetSearch, setResetSearch] = useState(false)
    const [chosenProductBeforePostalCodeCheck, setChosenProductBeforePostalCodeCheck] = useState<string | null>(null)
    const hasEnsuredCart = useRef(false);
    const [showPostcodeCheckButton, setPostcodeCheckButton] = useState(!hasPostcode)
    const { customer, isLoadingCustomer, isLoggingOut } = useAccount()
    const { klaviyo } = useKlaviyo()

    const handleSearch = () => {
        setsearchIsOpen(!searchIsOpen)
    }

    const handleResult = (result: string, postal_code: string, delivery_days: string[]) => {
        if (delivery_days?.length > 0) {
            setDeliveryDayForChosenPostalCode(delivery_days[0])
            //setCutOffDayForChosenPostalCode(subtractDays(delivery_days[0], CUTOFF_DAYS))
        }
        setPostalCodeCheckResult(result)
        setChosenPostalCode(postal_code)
    }

    const validatePostalCode = async (postalCode: string) => {
        if (window._mtm) {
            window._mtm.push({
                'event': 'CheckPostcode',
                'postcode': postalCode,
            });
        }
        const result = await medusaClient.client
            .request('GET', `/store/postal-codes/postal-code/${postalCode}`)
            .then(data => {
                return data
            })
            .catch(err => {
                return err?.response?.data
            })
        if (result?.code) {
            if (result?.type === 'valid') {
                await updateCart.mutateAsync(
                    {
                        shipping_address: {
                            postal_code: result.code,
                        },
                        context: {
                            ...cart?.context,
                            delivery_days: result.delivery_days,
                        },
                    },
                    {
                        onSuccess: data => {
                            if (data) {
                                setCart(data?.cart)
                                setPostcodeCheckButton(false)
                            }
                        },
                    },
                )
            }
            return handleResult(result?.type, postalCode, result?.delivery_days)
        }
    }

    useEffect(() => {
        if (cart?.context?.delivery_days) {
            const delivery_days = cart.context.delivery_days as string[]
            setDeliveryDayForChosenPostalCode(delivery_days[0])
            //setCutOffDayForChosenPostalCode(subtractDays(delivery_days[0], CUTOFF_DAYS))
        }
        if (cart?.shipping_address?.postal_code) {
            setChosenPostalCode(cart?.shipping_address?.postal_code)
        }
    }, [cart?.context?.delivery_days, cart?.shipping_address?.postal_code])

    const checkIfPostalCodeCheckIsNeeded = () => {
        if (
            cart?.id !== '' &&
            !cart?.shipping_address?.postal_code &&
            !isLoadingCustomer &&
            !customer?.shipping_addresses[0]?.postal_code
        ) {
            openPostcodeCheck()
            return true
        }
        return false
    }

    const createPostalCodeSignup = async (payload: PostalCodeSignupPayload) => {
        return await medusaClient.client
            .request('POST', '/store/postal-codes/postal-code/create', payload)
            .then(data => {
                return data
            })
            .catch(err => {
                return err?.response?.data
            })
    }
    const storeRegion = (regionId: string, countryCode: string) => {
        if (!IS_SERVER) {
            localStorage.setItem(REGION_KEY, JSON.stringify({ regionId, countryCode }))

            setCountryCode(countryCode)
        }
    }
    const updateQuantityUpdating = (variantOrLineId: string, updating = true) => {
        return setQuantityUpdating({ [variantOrLineId]: updating })
    }

    useEffect(() => {
        if (!IS_SERVER) {
            const storedRegion = localStorage.getItem(REGION_KEY)
            if (storedRegion) {
                const { countryCode } = JSON.parse(storedRegion)
                setCountryCode(countryCode)
            }
        }
    }, [])

    const getRegion = () => {
        if (!IS_SERVER) {
            const region = localStorage.getItem(REGION_KEY)
            if (region) {
                return JSON.parse(region) as { regionId: string; countryCode: string }
            }
        }
        return null
    }

    const setRegion = async (regionId: string, countryCode: string) => {
        await updateCart.mutateAsync(
            {
                region_id: regionId,
            },
            {
                onSuccess: ({ cart }) => {
                    setCart(cart)
                    storeCartId(cart.id)
                    storeRegion(regionId, countryCode)
                },
                onError: error => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(error)
                    }
                },
            },
        )
    }

    const setEmail = async (email: string) => {
        await updateCart.mutateAsync(
            {
                email: email,
            },
            {
                onSuccess: ({ cart }) => {
                    setCart(cart)
                    storeCartId(cart.id)
                },
                onError: error => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(error)
                    }
                },
            },
        )
    }

    const ensureRegion = (region: Region, countryCode?: string | null) => {
        if (!IS_SERVER) {
            const { regionId, countryCode: defaultCountryCode } = getRegion() || {
                regionId: region.id,
                countryCode: region.countries[0].iso_2,
            }

            const finalCountryCode = countryCode || defaultCountryCode

            if (regionId !== region.id) {
                setRegion(region.id, finalCountryCode)
            }

            storeRegion(region.id, finalCountryCode)
            setCountryCode(finalCountryCode)
        }
    }

    const addProductBeforePostalCodeCheck = (id: string | null) => {
        setChosenProductBeforePostalCodeCheck(id)
    }

    const getProductBeforePostalCodeCheck = () => {
        return chosenProductBeforePostalCodeCheck
    }

    const storeCartId = (id: string) => {
        if (!IS_SERVER) {
            localStorage.setItem(CART_KEY, id)
        }
    }

    const getCartId = () => {
        if (!IS_SERVER) {
            return localStorage.getItem(CART_KEY)
        }
        return null
    }

    const deleteCartId = () => {
        if (!IS_SERVER) {
            localStorage.removeItem(CART_KEY)
        }
    }

    const deleteRegion = () => {
        if (!IS_SERVER) {
            localStorage.removeItem(REGION_KEY)
        }
    }

    const createNewCart = async (regionId?: string) => {
        if (!isCreatingNewCart.current) {
            isCreatingNewCart.current = true
            try {
                const response = await createCart.mutateAsync({ region_id: regionId });
                setCart(response.cart)
                storeCartId(response.cart.id)
                ensureRegion(response.cart.region, response.cart.shipping_address?.country_code)
                isCreatingNewCart.current = false
            } catch (error) {
                isCreatingNewCart.current = false
                if (process.env.NODE_ENV === 'development') {
                    console.error(error)
                }
            }
        }
    }

    const resetCart = () => {
        setChosenPostalCode(null)
        setPostalCodeCheckResult(null)
        deleteCartId()

        const savedRegion = getRegion()

        createCart.mutate(
            {
                region_id: savedRegion?.regionId,
            },
            {
                onSuccess: ({ cart }) => {
                    setCart(cart)
                    storeCartId(cart.id)
                    ensureRegion(cart.region, cart.shipping_address?.country_code)
                },
                onError: error => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(error)
                    }
                },
            },
        )
    }

    useEffect(() => {
        if (isLoggingOut) return

        const loadOrCreateCart = async () => {
            if (subscription === undefined) return; // Still loading subscription

            const cartId = subscription ? subscription.cart.id : getCartId()
            const region = getRegion()

            if (cartId) {
                await medusaClient.carts
                    .retrieve(cartId)
                    .then(async ({ cart }) => {
                        if (!cart) {
                            deleteRegion()
                            await createNewCart()
                        } else {
                            setCart(cart)
                            ensureRegion(cart.region)
                            storeCartId(cartId)

                        }
                    })
                    .catch(async _ => {
                    })
            } else {
                await createNewCart(region?.regionId)
            }
        }

        loadOrCreateCart()
    }, [subscription, isLoggingOut])

    useEffect(() => {
        if (cart && klaviyo) {
            klaviyo.identify({
                "$id": cart.id,
            }, () => {console.log('klaviyo identify')})
        }
    }, [cart, klaviyo])

    const frequencyOptions = useMemo(() => {
        return [
            { value: 0, label: 'Just this delivery' },
            { value: 1, label: 'Weekly' },
            { value: 2, label: 'Every 2 weeks' },
            { value: 3, label: 'Every 3 weeks' },
            { value: 4, label: 'Every 4 weeks' },
            { value: 5, label: 'Every 5 weeks' },
            { value: 6, label: 'Every 6 weeks' },
            { value: 7, label: 'Every 7 weeks' },
            { value: 8, label: 'Every 8 weeks' },
        ]
    }, [])

    const addItem = (variantId: string, quantity: number, payload?: Record<string, string>) => {
        updateQuantityUpdating(variantId)
        addLineItem.mutate(
            {
                variant_id: variantId,
                quantity: quantity,
                ...payload,
            },
            {
                onSuccess: ({ cart }) => {
                    setCart(cart)
                    storeCartId(cart.id)
                    // timedOpen()
                },
                onError: error => {
                    handleError(error)
                },
                onSettled: () => updateQuantityUpdating(variantId, false),
            },
        )
    }

    const deleteItem = (lineId: string) => {
        updateQuantityUpdating(lineId)
        removeLineItem.mutate(
            {
                lineId: lineId,
            },
            {
                onSuccess: ({ cart }) => {
                    setCart(cart)
                    storeCartId(cart.id)
                },
                onError: error => {
                    handleError(error)
                },
                onSettled: () => updateQuantityUpdating(lineId, false),
            },
        )
    }

    const updateItem = (
        lineId: string,
        quantity: number,
        payload?: Record<string, string | number | null>,
        callback?: () => void,
    ) => {
        updateQuantityUpdating(lineId)
        adjustLineItem.mutate(
            {
                lineId,
                quantity,
                ...payload,
            },
            {
                onSuccess: ({ cart }) => {
                    callback && callback()
                    setCart(cart)
                    storeCartId(cart.id)
                },
                onError: error => {
                    handleError(error)
                },
                onSettled: () => {
                    updateQuantityUpdating(lineId, false)
                },
            },
        )
    }

    const openPostcodeCheck = () => {
        if (window._mtm) {
            window._mtm.push({
                'event': 'OpenPostcodeChecker',
            });
        }

        setShowPostalCodeCheck(true)
    }

    return (
        <StoreContext.Provider
            value={{
                getProductBeforePostalCodeCheck,
                addProductBeforePostalCodeCheck,
                checkIfPostalCodeCheckIsNeeded,
                postalCodeCheckResult,
                setPostalCodeCheckResult,
                chosenPostalCode,
                nextDeliveryDay,
                setChosenPostalCode,
                setDeliveryDayForChosenPostalCode,
                deliveryDayForChosenPostalCode,
                setShowPostalCodeCheck,
                showPostalCodeCheck,
                resetSearch,
                setResetSearch,
                searchIsOpen,
                handleSearch,
                validatePostalCode,
                createPostalCodeSignup,
                countryCode,
                setRegion,
                addItem,
                deleteItem,
                updateItem,
                resetCart,
                quantityUpdating,
                setEmail,
                deleteCartId,
                showPostcodeCheckButton,
                setPostcodeCheckButton,
                openPostcodeCheck,
                frequencyOptions,
            }}
        >
            {children}
        </StoreContext.Provider>
    )
}
