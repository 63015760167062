export const dayMapping: { [key: string]: number } = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 0,
}

// Utility function to get the day name from day number
export const getDayNameByNumber = (dayNumber: number): string => {
    // Find the key in dayMapping whose value matches dayNumber
    for (const [key, value] of Object.entries(dayMapping)) {
        if (value === dayNumber) {
            return key
        }
    }
    throw new Error('Day not found')
}

// Function to find the day, subtract two days, and return the new day name
export const subtractDays = (dayName: string, days: number): string => {
    const currentDayNumber = dayMapping[dayName.toLowerCase()]
    if (currentDayNumber === undefined) {
        throw new Error('Invalid day name provided')
    }

    // Subtract two days and wrap around using modulus
    let newDayNumber = (currentDayNumber - 2) % 7
    // If the result is negative, wrap it to the end of the week
    if (newDayNumber < 0) {
        newDayNumber += 7
    }

    return getDayNameByNumber(newDayNumber)
}
