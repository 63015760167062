'use client'

import { handleError } from '@lib/util/handle-error'
import React, { useEffect, useState } from 'react'
import { Subscription } from '../../types/medusa'
import { medusaClient } from '@lib/config'
import { subtractDays } from '@lib/util/date'
import { CUT_OFF_DAYS } from '@lib/constants'

interface SubscriptionContext {
    subscription: Subscription | null
    setSubscription: (subscription: Subscription | null) => void
    subscriptionQuantityUpdating?: { [key: string]: boolean }
    updateSubscription: (subscriptionId: string, payload: Record<string, string>) => void
    isUpdatingSubscription: boolean
    cancelSubscription: (subscriptionId: string) => Promise<void>
    activateSubscription: () => Promise<void>
    cutOffDaySubscription: string | undefined
}

const SubscriptionContext = React.createContext<SubscriptionContext | null>(null)

export const useSubscription = () => {
    const context = React.useContext(SubscriptionContext)
    if (context === null) {
        throw new Error('useSubscription must be used within a SubscriptionProvider')
    }
    return context
}

interface SubscriptionProps {
    children: React.ReactNode
}

export const SubscriptionProvider = ({ children }: SubscriptionProps) => {
    const [subscription, setSubscription] = useState<Subscription | null>(null)
    const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(false)
    const [cutOffDaySubscription, setCutOffDaySubscription] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (subscription) {
            setCutOffDaySubscription(subtractDays(new Date(subscription?.renewal_at).toLocaleDateString('en-US', {weekday: 'long'}).toLocaleLowerCase(), CUT_OFF_DAYS))
        }
    }, [subscription])

    const updateSubscription = async (subscriptionId: string, payload: Record<string, string>) => {
        setIsUpdatingSubscription(true)
        await medusaClient.client
            .request('POST', `/store/customers/me/subscriptions/new/${subscriptionId}`, payload)
            .then(res => {
                setIsUpdatingSubscription(false)
                setSubscription(res)
            })
            .catch(error => {
                setIsUpdatingSubscription(false)
                handleError(error)
            })
    }

    const cancelSubscription = async (subscriptionId: string) => {
        setIsUpdatingSubscription(true)
        await medusaClient.client
            .request('DELETE', `/store/customers/me/subscriptions/${subscriptionId}`)
            .then(res => {
                setSubscription(null)
                setIsUpdatingSubscription(false)
            })
            .catch(error => {
                setIsUpdatingSubscription(false)
                handleError(error)
            })
    }

    const activateSubscription = async () => {
        setIsUpdatingSubscription(true)
        await medusaClient.client
            .request('POST', `/store/customers/me/subscriptions/activate`)
            .then(res => {
                setSubscription(res.subscription)
                setIsUpdatingSubscription(false)
            })
            .catch(error => {
                setIsUpdatingSubscription(false)
                handleError(error)
            })
    }

    return (
        <SubscriptionContext.Provider
            value={{
                subscription,
                setSubscription,
                updateSubscription,
                isUpdatingSubscription,
                cancelSubscription,
                activateSubscription,
                cutOffDaySubscription,
            }}
        >
            {children}
        </SubscriptionContext.Provider>
    )
}
