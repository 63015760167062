function getOrdinalSuffix(date) {
    if (!date) {
        return '';
    }
    const day = date.getDate();
    if (day > 3 && day < 21)
        return day + 'th';
    switch (day % 10) {
        case 1: return day + 'st';
        case 2: return day + 'nd';
        case 3: return day + 'rd';
        default: return day + 'th';
    }
}
export default getOrdinalSuffix;
